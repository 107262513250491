import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import cn from 'classnames'

import Page from '../../components/Page'
import SEO from '../../components/SEO'
import Schema from '../../components/Schema'
import LeadSpot from '../../components/solutions/LeadSpot'
import ExpandableImage from '../../components/ExpandableImage'
import CallToAction from '../../components/primary/CallToAction'
import Newsletter from '../../components/Newsletter'

import { isEven } from '../../utils/num'
import Testimonials from '../../components/Testimonials'

const PimPage = () => {
  const { markdownRemark, allDatoCmsTestimonial } = useStaticQuery(graphql`
    query PimPageQuery {
      markdownRemark(frontmatter: { path: { eq: "/pim" } }) {
        frontmatter {
          title
          description
          path
          lead {
            ...LeadSpotFragment
            ...LeadStreamerFragment
          }
          section3 {
            title
            text
            image {
              modal: childImageSharp {
                ...SharpImageModalFragment
              }
              thumb: childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            imageExpandable
          }
          alsoIncluded {
            title
            text
            icon
          }
          ...CallToActionFragment
        }
      }
      recipeCardsImage: file(
        relativePath: { eq: "solutions/recipe-builder/recipe-cards.jpg" }
      ) {
        modal: childImageSharp {
          ...SharpImageModalFragment
        }
        thumb: childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      allDatoCmsTestimonial(
        filter: { categories: { elemMatch: { title: { eq: "PIM" } } } }
        limit: 1
      ) {
        edges {
          node {
            ...TestimonialsFragment
          }
        }
      }
    }
  `)

  const pageData = markdownRemark.frontmatter
  const testimonials = allDatoCmsTestimonial.edges

  return (
    <Page className="p-solutions p-solutions--wholesalers has-fingerPrints">
      <SEO title={pageData.title} description={pageData.description} />
      <Schema
        breadcrumbs={[
          {
            name: pageData.title,
            uri: pageData.path,
          },
        ]}
      />

      <LeadSpot
        title={pageData.lead.spot.title}
        header={pageData.lead.spot.header}
        imageFolder="pim"
      />

      <section className="c-section c-section--forLeadSteamer">
        <div className="c-wave c-wave--top">
          <svg viewBox="0 0 500 150" preserveAspectRatio="none">
            <path
              style={{ stroke: 'none', fill: 'white' }}
              d="M-0.84,110.03 C259.87,-110.03 333.80,252.13 500.84,76.47 L500.00,0.00 L0.00,0.00 Z"
            ></path>
          </svg>
        </div>

        <div className="c-section__outer container">
          <div className="c-section__inner">
            <div className="c-forLeadSteamer row">
              <div className="col-12 col-lg-6 col-xl-7">
                <h2 className="c-forLeadSteamer__header">
                  {pageData.lead.streamer.title}
                </h2>
                <p
                  className="c-forLeadSteamer__text"
                  dangerouslySetInnerHTML={{
                    __html: pageData.lead.streamer.text,
                  }}
                />
              </div>
              <div className="c-forLeadSteamer__icon col-12 col-lg-6 col-xl-5 text-right pt-4">
                <img
                  className="img-fluid"
                  width={pageData.lead.streamer.imageWidth}
                  src={`/images/icons/${pageData.lead.streamer.image}.png`}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="c-wave c-wave--bottom">
          <svg viewBox="0 0 500 150" preserveAspectRatio="none">
            <path
              style={{ stroke: 'none', fill: 'white' }}
              d="M-0.84,113.97 C271.16,40.95 365.97,59.70 500.84,81.41 L500.00,150.00 L0.00,150.00 Z"
            ></path>
          </svg>
        </div>
      </section>

      <section className="c-section c-section--postStreamer">
        <div className="c-section__outer container">
          <div className="c-section__inner">
            <div className="c-postSteamer text-left">
              {pageData.section3.map((blurb, i) => (
                <div key={`section3-${i}`} className="row">
                  <div
                    className={cn(
                      'col-12 col-md-6 mb-5 d-flex flex-column justify-content-center',
                      { 'order-md-2': !isEven(i) }
                    )}
                  >
                    <h2
                      className="mb-4"
                      dangerouslySetInnerHTML={{ __html: blurb.title }}
                    />
                    <div className="c-postStreamer__text">
                      <p dangerouslySetInnerHTML={{ __html: blurb.text }} />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-5 text-center">
                    <section className="car-card">
                      <ConditionalWrapper
                        condition={blurb.imageExpandable}
                        wrapper={(children) => (
                          <ExpandableImage
                            imageData={blurb.image.modal}
                            bottomRight={isEven(i)}
                          >
                            {children}
                          </ExpandableImage>
                        )}
                      >
                        <Img
                          fluid={blurb.image.thumb.fluid}
                          title={blurb.title}
                          alt={blurb.title}
                          className="c-postStreamer__image img-fluid"
                        />
                      </ConditionalWrapper>
                    </section>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section
        id="benefits-to-you"
        className="c-section c-section--forBenefits c-section--forBenefits--you"
      >
        <div className="c-section__outer container">
          <div className="c-section__tag">Also Includes</div>
          <div className="c-section__inner">
            <div className="c-forBenefits">
              {pageData.alsoIncluded.map((blurb) => (
                <div key={blurb.title} className="c-forBenefitsInfo row">
                  <div className="c-forBenefitsInfo__block c-forBenefitsInfo__block--icon col-12 col-md-3">
                    <img
                      className="c-forBenefitsInfo__icon img-fluid"
                      src={`/images/icons/${blurb.icon}.svg`}
                      loading="lazy"
                    />
                  </div>

                  <div className="c-forBenefitsInfo__block c-forBenefitsInfo__block--text col-12 col-md-7">
                    <h2 className="c-forBenefitsInfo__header">{blurb.title}</h2>
                    <p dangerouslySetInnerHTML={{ __html: blurb.text }} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <Testimonials testimonials={testimonials} />

      <CallToAction ctaData={pageData.signUpCta} fill={`#f4f4f4`} />

      <Newsletter />
    </Page>
  )
}

export default PimPage

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children
