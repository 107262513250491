import React, { useRef, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'

import ArrowLeftSVG from './../assets/svg/arrow-left.svg'
import ArrowRightSVG from './../assets/svg/arrow-right.svg'
import DotSVG from './../assets/svg/dot.svg'

import Glide, { Controls } from '@glidejs/glide/dist/glide.modular.esm'
import '@glidejs/glide/dist/css/glide.core.min.css'

const Testimonials = ({ testimonials, tag }) => {
  const { allDatoCmsTestimonial } = useStaticQuery(graphql`
    query TestimonialQuery {
      allDatoCmsTestimonial(
        filter: { categories: { elemMatch: { title: { eq: "Generic" } } } }
        limit: 8
      ) {
        edges {
          node {
            ...TestimonialsFragment
          }
        }
      }
    }
  `)

  const allTestimonials =
    testimonials.length > 0 ? testimonials : allDatoCmsTestimonial.edges

  // Set reference for slider element
  const sliderRef = useRef(null)

  useEffect(() => {
    // Attach slider on mount
    const testimonialSlider = sliderRef.current
    const testimonialGlider = new Glide(testimonialSlider, {
      focusAt: 'center',
      perView: 1,
    }).mount({ Controls })

    // Remove slider on cleanup
    return () => testimonialGlider.destroy()
  }, []) // Empty array ensures that effect is only run on mount

  return (
    <section id="testimonials" className="c-testimonial c-section">
      <div className="c-testimonial__outer container">
        {tag && <div className="c-section__tag">{tag}</div>}
        <div className="c-testimonial__inner">
          <div ref={sliderRef} className="slider">
            <div className="glide__track" data-glide-el="track">
              <ul className="glide__slides c-testimonialSlides">
                {allTestimonials.map(({ node }) => (
                  <li key={node.id} className="glide__slide">
                    <div className="c-testimonialItem glide__slide row">
                      <div className="col-12 col-md-3 order-last order-md-first">
                        <div className="c-testimonial__author">
                          {node.avatar && (
                            <Img
                              fixed={node.avatar.fixed}
                              className="c-testimonial__author__avatar"
                              alt={node.avatar.alt}
                              title={node.avatar.title}
                            />
                          )}
                          <span className="c-testimonial__author__citation">
                            <strong>{node.name}</strong>
                            <br />
                            {node.jobTitle}
                            <br />
                            {node.companyName}
                          </span>
                        </div>
                        <img
                          className="c-testimonial__compLogo"
                          src={node.companyLogo.fixed.src}
                          srcSet={node.companyLogo.fixed.srcSet}
                          alt={node.companyLogo.alt}
                          title={node.companyLogo.title}
                          height={node.companyLogo.height}
                          width={node.companyLogo.width}
                          loading="lazy"
                        />
                      </div>

                      <div className="col-12 col-md-9 order-first order-md-last">
                        <q className="c-testimonial__body">{node.body}</q>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            {allTestimonials.length > 1 && (
              <div className="glide__arrows" data-glide-el="controls">
                <button
                  className="glide__arrow glide__arrow--left mr-3"
                  data-glide-dir="<"
                  aria-label="Show previous testimonial"
                >
                  <ArrowLeftSVG className="c-icon" />
                </button>
                <div className="glide__bullets" data-glide-el="controls[nav]">
                  {allTestimonials.map(({ node }, index) => (
                    <span
                      key={node.id}
                      className="glide__bullet"
                      data-glide-dir={`=${index}`}
                      aria-label={`Show testimonial ${index + 1}`}
                    >
                      <DotSVG />
                    </span>
                  ))}
                </div>
                <button
                  className="glide__arrow glide__arrow--right ml-3"
                  data-glide-dir=">"
                  aria-label="Show next testimonial"
                >
                  <ArrowRightSVG className="c-icon" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

Testimonials.defaultProps = {
  testimonials: [],
  tag: undefined,
}

Testimonials.propTypes = {
  testimonials: PropTypes.arrayOf(PropTypes.object),
  tag: PropTypes.string,
}

export default Testimonials

export const FragmentQuery = graphql`
  fragment TestimonialsFragment on DatoCmsTestimonial {
    id
    name
    jobTitle
    body
    companyName
    companyLogo {
      fixed(height: 90) {
        ...GatsbyDatoCmsFixed
      }
      alt
      title
    }
    avatar {
      fixed(
        height: 70
        width: 70
        imgixParams: { mask: "ellipse", fit: "crop" }
      ) {
        ...GatsbyDatoCmsFixed
      }
      alt
      title
    }
    categories {
      title
    }
  }
`
